import {lazy} from 'react'
let router = [
    {
        path: "/login",
        component: lazy(()=>import('@/views/Login')),
        redirect: true,// 默认打开登录页
        exact: true //是否为严格匹配
    },
    {
        path: '/mobileProjectList',
        component:  lazy(()=>import('@/pages/project/mobile/projectList')),
        // component: lazy(()=>import('@/views/mobile')),
        exact: true
    },
    {
        path: '/mobileProjectDetail',
        component:  lazy(()=>import('@/pages/project/mobile/projectDetail')),
        // component: lazy(()=>import('@/views/mobile')),
        exact: true
    },
    {
        path: '/',
        component: lazy(()=>import('@/views/MyView')),
        exact: false,
        routes: [  /** 嵌套路由 */
            // {
            //     path: '/home',
            //     component: lazy(()=>import('@/pages/Home')),
            //     exact: false
            // },
            // {
            //     path: '/grid',
            //     component: lazy(()=>import('@/pages/Grid')),
            //     exact: false
            // },
            // {
            //     path: '/layout',
            //     component: lazy(()=>import('@/pages/Layout')),
            //     exact: false
            // },
            // {
            //     path: '/basicForm',
            //     component: lazy(()=>import('@/pages/form/BasicForm')),
            //     exact: false
            // },
            // {
            //     path: '/customVerif',
            //     component: lazy(()=>import('@/pages/form/CustomVerif')),
            //     exact: false
            // },
            // {
            //     path: '/basicTable',
            //     component: lazy(()=>import('@/pages/table/BasicTable')),
            //     exact: false
            // },
            {
                path: '/projectManage',
                component: lazy(()=>import('@/pages/project/projectManage')),
                exact: false
            },
            {
                path: '/projectDetail',
                component: lazy(()=>import('@/pages/project/projectDetail')),
                exact: false
            },
            {
                path: '/userManage',
                component: lazy(()=>import('@/pages/user/userManage')),
                exact: false
            },
            {
                path: '/userDetail',
                component: lazy(()=>import('@/pages/user/userDetail')),
                exact: false
            },
            {
                path: '/logs',
                component: lazy(()=>import('@/pages/log/logList')),
                exact: false
            },
            // {
            //     path: '/redirect',
            //     component: lazy(()=>import('@/pages/Redirect')),
            //     exact: false
            // },
            {
                path: '*',
                component: lazy(()=>import('@/compontens/MyNotFound')),
                meta: {
                    title: '404'
                }
            },
        ]
    }
];

export default router;